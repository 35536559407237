var env = {};
if (window) {
    //Object.assign(env, window.__env); // Esto no funciona en Cordova
    env = JSON.parse(JSON.stringify(window.__env));
}

angular.module('app', ['ui.timepicker', 'ngRoute', 'ui.bootstrap', 'angularLocalStorage', 'uiGmapgoogle-maps', 'uiGmapgoogle-maps', 'ngFileUpload', 'ngAnimate', 'angular-loading-bar', 'home', 'auth', 'login', 'elementosCuadroPreciosAdmin', 'cultivosAprovechamientosAdmin', 'terminosMunicipalesAdmin', 'registrosMunicipalesAdmin', 'carreterasAdmin', 'usuariosAdmin', 'tiposInteresAdmin', 'plantillasWordAdmin', 'usuarios', 'claves', 'expedientes', 'controlexpedientes'])
    //.constant('serviceUrl', 'http://localhost:62172/')
    //.constant('serviceUrl', 'https://api.aragon.gestionexpropiaciones.es/')
    //.constant('serviceUrl', 'https://api.cantabria.gestionexpropiaciones.es/')
    //.constant('serviceUrl', 'http://ptfdesarrollo/ExpropiacionesAPI/')
    .constant('__env', env)
    .config(['$routeProvider', function ($routeProvider) {
        $routeProvider
            .when('/home', {
                templateUrl: 'app/home/home.html',
                title: 'Inicio'
            })

            .when('/admin/usuarios/nuevo', {
                 templateUrl: 'app/admin/usuarios/nuevoUsuarioAdmin.html',
                 title: 'Nuevo Usuario'
             })
            .when('/admin/usuarios', {
                templateUrl: 'app/admin/usuarios/usuariosAdmin.html',
                title: 'Gestión Usuario'
            })
            .when('/usuario/config', {
                templateUrl: 'app/usuario/editarUsuario.html',
                title: 'Modificar Usuario'
            })
            .when('/usuario/password/:id', {
                templateUrl: 'app/usuario/cambiarPwd.html',
                title: 'Modificar Contraseña Usuario'
            })
            .when('/admin/usuarios/:id', {
                templateUrl: 'app/admin/usuarios/editarUsuarioAdmin.html',
                title: 'Gestión Usuarios'
            })
            .when('/admin/elementoscuadroprecios', {
                templateUrl: 'app/admin/elementosCuadroPrecios/elementosCuadropreciosAdmin.html',
                title: 'Cuadro de Precios'
            })
            .when('/admin/calificacionesfincas', {
                templateUrl: 'app/admin/cultivosAprovechamientos/cultivosAprovechamientosAdmin.html',
                title: 'Cultivos/Aprovechamientos de Fincas'
            })
            .when('/admin/terminosmunicipales', {
                templateUrl: 'app/admin/terminosMunicipales/terminosMunicipalesAdmin.html',
                title: 'Términos Municipales'
            })
            .when('/admin/registrosmunicipales', {
                templateUrl: 'app/admin/registrosMunicipales/registrosMunicipalesAdmin.html',
                title: 'Registros Municipales'
            })
            .when('/admin/carreteras', {
                templateUrl: 'app/admin/carreteras/carreterasAdmin.html',
                title: 'Carreteras'
            })
            .when('/admin/tiposinteres', {
                templateUrl: 'app/admin/tiposInteres/tiposInteresAdmin.html',
                title: 'Tipos Interés'
            })
            .when('/admin/plantillasword', {
                templateUrl: 'app/admin/plantillasword/plantillaswordAdmin.html',
                title: 'Plantillas Word'
            })

            .when('/claves', {
                templateUrl: 'app/claves/claves.html',
                title: 'Claves'
            })
            .when('/claves/:id', {
                templateUrl: 'app/claves/clave.html',
                title: 'Claves'
            })
            .when('/expedientes/:id', {
                templateUrl: 'app/expedientes/expedientes.html',
                title: 'Expedientes'
            })
            .when('/expedientes/:idClave/:id', {
                templateUrl: 'app/expedientes/expediente.html',
                title: 'Expediente'
            })
            .when('/expedientes/:idClave/:id/:copiado', {
                templateUrl: 'app/expedientes/expediente.html',
                title: 'Expediente'
            })
            .when('/expedientestitular/:id', {
                templateUrl: 'app/expedientes/expedientestitular.html',
                title: 'Expedientes'
            })
            .when('/expedientestit/:idClave/:id/:tit', {
                templateUrl: 'app/expedientes/expediente.html',
                title: 'Expediente'
            })

            .when('/controlexpedientes/:idClave/:idTerminoMunicipal/', {
                templateUrl: 'app/controlexpedientes/controlexpedientes.html',
                title: 'Control Expedientes'
            })

            .otherwise({
                redirectTo: '/home'
            });
    }])

    .config(['$httpProvider', function ($httpProvider) {
        $httpProvider.interceptors.push('authInterceptor');
    }])
    .config(['uiGmapGoogleMapApiProvider', function (GoogleMapApiProviders) {
        GoogleMapApiProviders.configure({
            key: 'AIzaSyDyBqjZw_PDaFhPHtioHikI1Avd3qSApDc',
            v: '3.24',
            libraries: 'weather,geometry,visualization'
        });
    }])
    .run(['$rootScope', '$location', '$route', 'authenticationService', 'authEvents', '$http',
        function ($rootScope, $location, $route, authenticationService, authEvents, $http) {
            $rootScope.$on(authEvents.login, function () {
                $location.path('/claves');
            });
            $rootScope.$on(authEvents.logout, function () {
                $location.path('/home');
            });
        }])

   .directive('ngTextoConfirmar', [
        function () {
            return {
                link: function (scope, element, attr) {
                    var msg = attr.ngTextoConfirmar || "¿Desea completar la operación?";
                    var clickAction = attr.ngClickConfirmado;
                    element.bind('click', function (event) {
                        if (window.confirm(msg)) {
                            scope.$apply(clickAction)
                        }
                    });
                }
            };
        }])
    /*.directive('fileModel', ['$parse', function ($parse) {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                element.bind('change', function () {
                    $parse(attrs.fileModel).assign(scope, element[0].files)
                    scope.$apply();
                });
            }
        };
    }])*/

    .factory('onlineStatus', ["$window", "$rootScope", function ($window, $rootScope) {
        var onlineStatus = {};

        onlineStatus.onLine = $window.navigator.onLine;
        //onlineStatus.onLine = false;

        onlineStatus.isOnline = function () {
            return onlineStatus.onLine;
        }

        $window.addEventListener("online", function () {
            onlineStatus.onLine = true;
            $rootScope.$digest();
        }, true);

        $window.addEventListener("offline", function () {
            onlineStatus.onLine = false;
            $rootScope.$digest();
        }, true);

        return onlineStatus;
    }])
.factory('Utilidades', function () {
    var interfaz = {
        getDatePickerOptions: function () {
            var fechaActual = new Date();
            var fechaIni = fechaActual.getUTCFullYear() + '/' + (fechaActual.getUTCDate()) + '/' + (fechaActual.getUTCMonth() + 1);
            var datepickerOptions = {
                startingDay: 1,
                startDate: new Date(fechaIni)
            }
            return datepickerOptions;
        },
        stringFormat: function (valorNumerico) {
            var separador= '.'; // separador para los miles
            var sepDecimal = ','; // separador para los decimales
            //2 decimales
            valorNumerico = parseFloat(valorNumerico.toFixed(2));
            valorNumerico += '';
            var splitStr = valorNumerico.split('.');
            var splitLeft = splitStr[0];
            var splitRight = splitStr.length > 1 ? sepDecimal + splitStr[1] : '';
            var regx = /(\d+)(\d{3})/;
            while (regx.test(splitLeft)) {
                splitLeft = splitLeft.replace(regx, '$1' + separador + '$2');
            }
            return splitLeft + splitRight;
        },
        numberFormat: function (stringNumerico) {
            if (stringNumerico == undefined) return stringNumerico;
            if (stringNumerico === null) return 0;
            if (stringNumerico === '') return 0;
            stringNumerico = stringNumerico.replace(/\./g, '');
            stringNumerico = stringNumerico.replace(',', '.');
            return parseFloat(stringNumerico);
        }
    }
    return interfaz;
})

.factory("Excel", function () {
    var uri = 'data:application/vnd.ms-excel;base64,'
          , template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head> <meta http-equiv="content-type" content="text/plain; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table  border="1">{table}</table></body></html>'
          , base64 = function (s) { return window.btoa(unescape(encodeURIComponent(s))) }
          , format = function (s, c) { return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; }) }
    return {
        exportar: function (div, table, name) {
            //Si el navegador es IE
            if (navigator.msSaveBlob) {
                return navigator.msSaveBlob(new Blob([document.getElementById(div).innerHTML], {
                    type: "application/vnd.ms-excel"
                }), name + ".xls");
            }
            else {
                if (!table.nodeType) table = document.getElementById(table)
                var ctx = { worksheet: name || 'Worksheet', table: table.innerHTML }
                var link = document.createElement("a");
                link.download = name + ".xls";
                link.href = uri + base64(format(template, ctx));
                link.click();
            }

        }

    }
    })
    .filter('unsafe', ['$sce', function ($sce) { return $sce.trustAsHtml; }])
    
    /// Filtro de expedientes para el listado de expedientes
    /// Implementa el filtro estándar (cuando se le pasa un objeto), es decir, 
    /// comprueba para cada campo si contiene la cadena a buscar en ese campo.
    /// La diferencia está en el campo "Estado", que lo ordena de forma que 
    /// salgan primero los expedientes que empiezan por esa cadena y después el
    /// resto
    .filter('filtroExpedientes', function ($filter) { 
        return function (items, expedienteBuscar) {
            if (!expedienteBuscar)
                return items;
            
            // Filtro estándar
            var filtrados = $filter('filter')(items, expedienteBuscar);
            
            // Reordenar según estado
            filtrados.sort(comparadorExpedientesPorEstado(expedienteBuscar.Estado));
            
            return filtrados;
        };
    })

.directive('multipleUploadDirectory', ['$parse', function ($parse) {
    function link(scope, element, attrs) {
        var model = $parse(attrs.multipleUploadDirectory);
        element.on('change', function (event) {
            scope.archivosMultiples = [];    //Clear shared scope in case user reqret on the selection
            model(scope, { file: event.target.files });
        });
    };
    return {
        link: link
    }
}])
.directive("replaceComma", function () {
    return {
        restrict: "A",
        link: function (scope, element) {
            element.on("keydown", function (e) {
                if (e.keyCode === 188) {
                    this.value += ".";
                    e.preventDefault();
                }
            });
        }
    };
})  
.directive("replaceDot", function () {
    return {
        restrict: "A",
        link: function (scope, element) {
            element.on("keydown", function (e) {
                if (e.keyCode === 110 || e.keyCode === 190) {
                    this.value += ",";
                    e.preventDefault();
                }
            });
        }
    };
})

.directive('currencyInput', function ($filter, $browser) {
    return {
        require: 'ngModel',
        link: function ($scope, $element, $attrs, ngModelCtrl) {
            $element.addClass('numberInput');
            var separators = {
                'thousands': $filter('number')(1000).substr(1, 1),
                'decimal': $filter('number')(1.1).substr(1, 1)
            }
            var decimalEntered = false;
            var listener = function () {
                var originalValue = $element.val();
                var value = originalValue.split(separators.thousands).join('').split(separators.decimal).join('.');
                if (decimalEntered) {
                    decimalEntered = false;
                    return;
                }
                var indicePunto = value.indexOf('.');
                if ( indicePunto != -1 && value.slice(-1) === '0')
                { // Número decimal con último caracter '0'. Asegurar que se muestra correctamente el último 0
                  // Mostramos la cadena original, para mantener el formato de separadores de mil, etc.
                    $element.val(originalValue);
                    return;
                    
                }
                $element.val($filter('number')(value));
            }

            // This runs when we update the text field
            ngModelCtrl.$parsers.push(function (viewValue) {
                return viewValue.split(separators.thousands).join('').split(separators.decimal).join(',');
            })

            // This runs when the model gets updated on the scope directly and keeps our view in sync
            ngModelCtrl.$render = function () {
                //$element.val($filter('number')(ngModelCtrl.$viewValue, false))
                //$element.val($filter('number', 3)(ngModelCtrl.$viewValue, true))
                $element.val(ngModelCtrl.$viewValue)
            }

            $element.bind('change', listener)
            $element.bind('keypress', function (event) {
                var key = event.which;
                // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
                // This lets us support copy and paste too
                if (key === 0 || key === 8 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
                    return
                }
                // ignore all other keys which we do not need
                if (
                    String.fromCharCode(key) !== separators.thousands
                    && String.fromCharCode(key) !== separators.decimal
                    && !(48 <= key && key <= 57)
                    && String.fromCharCode(key) !== '-'
                    ) {
                    event.preventDefault();
                    return;
                }
                if (String.fromCharCode(key) === separators.decimal) decimalEntered = true;
                $browser.defer(listener) // Have to do this or changes don't get picked up properly
            })

            $element.bind('paste cut', function () {
                $browser.defer(listener)
            })
        }
    }
})

/// Función de comparación para comparar expedientes en función del estado,
/// para uso en el filtro de expedientes.
/// Muestra primero los expedientes que empiezan por el estado a buscar y 
/// después el resto. Por ejemplo, si buscamos "MA", ordenará así:
/// MA, MA0, MA1... MAHA... FIN MA0, FIN MA1...
function comparadorExpedientesPorEstado(estadoBuscar) {
    return function (a, b) {
        if (!estadoBuscar)
            return 0;
        
        const estadoA = a.Estado.toLowerCase();
        const estadoB = b.Estado.toLowerCase();
        estadoBuscar = estadoBuscar.toLowerCase();
        
        var indiceA = estadoA.indexOf(estadoBuscar);
        var indiceB = estadoB.indexOf(estadoBuscar);
        
        if (indiceA == 0 && indiceB > 0)
            return -1;
        if (indiceA > 0 && indiceB == 0)
            return 1;

        return estadoA.localeCompare(estadoB);
    };
};

String.prototype.splice = function (idx, rem, s) {
    return (this.slice(0, idx) + s + this.slice(idx + Math.abs(rem)));
}
